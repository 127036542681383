import { LogoSVG } from "@components/LogoSVG"
import css from "@emotion/css"
import React from "react"
import { NavIcon } from "./NavIcon"
import { Link } from "gatsby"

const header = css`
  display: flex;
  padding: 1rem 2rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  svg {
    width: 8rem;
    height: 4rem;
  }
`

export function TopBar({ withLogo = true }) {
  return (
    <header css={header}>
      <Link to="/">
        <LogoSVG
          css={css`
            color: white;
            ${!withLogo && "visibility: hidden"};
          `}
        />
      </Link>
      <NavIcon />
    </header>
  )
}
