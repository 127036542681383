import { useEffect } from "react"

export function useOnClickOutside({ condition, ref, action }) {
  useEffect(() => {
    const listener = e => {
      if (!condition || !ref.current) return

      if (!ref.current.contains(e.target)) {
        action()
      }
    }

    document.addEventListener("click", listener)

    return () => {
      document.removeEventListener("click", listener)
    }
  }, [action, condition, ref])
}
