import React, { useState, useEffect, useRef } from "react"
import css from "@emotion/css"
import { colors } from "@theme/"
import { MaxWidth } from "@components/layout/max-width"
import { useInView } from "react-intersection-observer"
import { useSpring } from "react-spring"
import { animated } from "react-spring"

const team = css`
  background: white;
  text-align: center;
  h2 {
    color: ${colors.blue};
    text-align: left;
  }

  .image-container {
    position: absolute;
    height: 100%;
    display: flex;
    left: 0;
    top: 0;
    right: 0;
    img {
      visibility: hidden;
    }

    @media screen and (max-width: 50em) {
      width: 200%;
      left: -50px;
    }
  }
  .wrapper {
    @media screen and (min-width: 50em) {
      overflow: hidden;
    }
    position: relative;
    bottom: -7px;
    max-width: 1700px;
    margin: 0 auto;
    overflow-x: scroll;
    overflow-y: hidden;
    will-change: scroll-position;
    -webkit-overflow-scrolling: touch;
  }
  .main-image {
    max-width: 100%;
    @media screen and (max-width: 50em) {
      max-width: 200%;
      left: -50px;
      position: relative;
    }
  }
`

export function Team() {
  const initialUrl =
    "https://res.cloudinary.com/dxbygibug/image/upload/s--IXUlZQvI--/f_auto,q_jpegmini:1/v1511375938/homepage/team-initial.jpg"
  const [url, setUrl] = useState(initialUrl)
  const [ref, inView] = useInView({ rootMargin: "-100px" })
  const [nudge, setNudge] = useState(false)
  const wrapperRef = useRef()

  useEffect(() => {
    // check if element is overflowing
    const overflowing =
      wrapperRef.current.offsetWidth < wrapperRef.current.scrollWidth

    overflowing && inView ? setNudge(true) : setNudge(false)
  }, [inView, ref])

  const [props, set] = useSpring(() => ({
    from: { x: 0 },
  }))

  useEffect(() => {
    nudge ? set({ x: -50 }) : set({ x: 0 })
  }, [nudge, set])

  return (
    <section css={team} id="our-executive-team">
      <MaxWidth
        css={css`
          padding-bottom: 0;
          padding-top: 2em;
        `}
      >
        <h2>Our executive team</h2>
      </MaxWidth>

      <div className="wrapper" ref={wrapperRef}>
        <animated.div
          style={{
            transform: props.x.interpolate(x => `translateX(${x}px)`),
          }}
          css={css`
            @media screen and (min-width: 50em) {
              transform: none !important;
            }
          `}
        >
          <img src={url} alt="The team" className="main-image" ref={ref} />
          <section className="image-container">
            <TeamImage
              src="https://res.cloudinary.com/dxbygibug/image/upload/s--drNPlrrH--/f_auto,q_jpegmini:1/v1511375930/homepage/team-neil.jpg"
              alt="Neil Hoar - Director"
              setUrl={setUrl}
              initialUrl={initialUrl}
              width="24.08%"
            />
            <TeamImage
              src="https://res.cloudinary.com/dxbygibug/image/upload/s--tFcLPsDN--/f_auto,q_jpegmini:1/v1511375940/homepage/team-steven.jpg"
              alt="Steven King - Director"
              setUrl={setUrl}
              initialUrl={initialUrl}
              width="8.083%"
            />
            <TeamImage
              src="https://res.cloudinary.com/dxbygibug/image/upload/s--Lj_jPm9n--/f_auto,q_jpegmini:1/v1511375932/homepage/team-kez.jpg"
              alt="Kerrie Clempson - Senior Account Director"
              setUrl={setUrl}
              initialUrl={initialUrl}
              width="8.487%"
            />
            <TeamImage
              src="https://res.cloudinary.com/dxbygibug/image/upload/s--H7dNTW5L--/f_auto,q_jpegmini:1/v1511375932/homepage/team-mob.jpg"
              alt="Mark O'Brien - Media Director"
              setUrl={setUrl}
              initialUrl={initialUrl}
              width="8.314%"
            />
            <TeamImage
              src="https://res.cloudinary.com/dxbygibug/image/upload/s--U525zGSj--/f_auto,q_jpegmini:1/v1511375936/homepage/team-dan.jpg"
              alt="Dan O'Brien - Commercial Director"
              setUrl={setUrl}
              initialUrl={initialUrl}
              width="8.372%"
            />
            <TeamImage
              src="https://res.cloudinary.com/dxbygibug/image/upload/s--4l85VLIy--/f_auto,q_jpegmini:1/v1511375927/homepage/team-nat.jpg"
              alt="Natalie Buckton Collins - Art Director"
              setUrl={setUrl}
              initialUrl={initialUrl}
              width="9.18%"
            />
            <TeamImage
              src="https://res.cloudinary.com/dxbygibug/image/upload/s--ZYvZUB6m--/f_auto,q_jpegmini:1/v1511375941/homepage/team-steph.jpg"
              alt="Stephen Stamatatos - Response Manager"
              setUrl={setUrl}
              initialUrl={initialUrl}
              width="8.372%"
            />
            <TeamImage
              src="https://res.cloudinary.com/dxbygibug/image/upload/s--wxyAK3_p--/f_auto,q_jpegmini:1/v1511375940/homepage/team-paul.jpg"
              alt="Paul Freeman-Sanderson - Digital Director"
              setUrl={setUrl}
              initialUrl={initialUrl}
              width="25.52%"
            />
          </section>
        </animated.div>
      </div>
    </section>
  )
}

const TeamImage = React.memo(function TeamImage({
  setUrl,
  src,
  alt,
  initialUrl,
  width,
}) {
  return (
    <div
      style={{ width }}
      onMouseEnter={() => setUrl(src)}
      onMouseLeave={() => setUrl(initialUrl)}
    >
      <img src={src} alt={alt} />
    </div>
  )
})
