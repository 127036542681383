import { Apex } from "@components/home/sections/apex"
import { BetterWay } from "@components/home/sections/better-way"
import { Clients } from "@components/home/sections/clients"
import { MobileClients } from "@components/home/sections/clients-mobile"
import { Contact } from "@components/home/sections/contact"
import { DoYou } from "@components/home/sections/do-you"
import { Expertise } from "@components/home/sections/expertise"
import { FB } from "@components/home/sections/fb"
import { OurPeople } from "@components/home/sections/our-people"
import { Team } from "@components/home/sections/team"
import { Intro } from "@components/intro"
import { Meta } from "@components/meta"
import { NavProvider } from "@components/nav/container"
import { Slideout } from "@components/nav/slideout"
import { TopBar } from "@components/TopBar"
import { GlobalStyle } from "@layout/Global"
import { CanPlayProvider, Hero } from "@layout/Hero"
import React from "react"
import { Helmet } from "react-helmet"

function IndexPage() {
  return (
    <>
      <GlobalStyle />
      <Meta />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:400,800|Nunito+Sans:800"
          rel="stylesheet"
        />
      </Helmet>
      <CanPlayProvider>
        <NavProvider>
          <Slideout />
          <Hero>
            <TopBar />
            <Intro />
          </Hero>

          <DoYou />

          <BetterWay />

          <Apex />

          <Expertise />

          <OurPeople />

          <FB />

          <div id="our-clients">
            <Clients />

            <MobileClients />
          </div>

          <Contact />
        </NavProvider>
      </CanPlayProvider>

      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/7027378.js"
      ></script>
    </>
  )
}

export default IndexPage
