import React from "react"
import { MaxWidth } from "@components/layout/max-width"
import { useStaticQuery, graphql } from "gatsby"
import Shortcodes from "@jetshop/flight-shortcodes"
import css from "@emotion/css"
import { colors } from "@theme/"

const style = css`
  color: white;
  background: ${colors.navy};

  h3 {
    font-size: 1.25em;
    margin-bottom: 1.25em;
    font-weight: 600;
  }

  em {
    font-style: normal;
    font-weight: 700;
  }
`

export function BetterWay() {
  const { data } = useStaticQuery(graphql`
    query Maximise {
      data: datoCmsMaximise {
        title
        mainTitle
        contentNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)

  return (
    <section css={style}>
      <MaxWidth
        css={css`
          padding-top: 1em;
        `}
      >
        <h2>{data.mainTitle}</h2>
        <h3>{data.title}</h3>
        <Shortcodes
          content={data.contentNode.childMarkdownRemark.html}
          components={{
            em: Test,
          }}
        />
      </MaxWidth>
    </section>
  )
}

function Test(props) {
  return <em className="grad-underline" {...props} />
}
