import css from "@emotion/css"
import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { useNav } from "./container"

const links = [
  { name: "Home", path: "#home" },
  { name: "APEX", path: "#apex" },
  { name: "Our expertise", path: "#our-expertise" },
  { name: "Our people", path: "#our-people" },
  { name: "Our clients", path: "#our-clients" },
  { name: "Contact us", path: "#contact-us" },
]

const styles = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  list-style: none;
  height: 100%;
  padding-top: 6rem;
  margin: 0;
  max-height: 600px;

  li a {
    padding-left: 7em;
    padding-right: 1rem;
    text-align: right;
    color: white;
    display: block;
    width: 100%;
    margin: 0;
    text-decoration: none;
    font-weight: 800;
    font-size: 1.25rem;
    opacity: 0.7;
    cursor: pointer;
    :hover {
      opacity: 1;
    }
  }
`

export function Menu() {
  const { setNavOpen } = useNav()
  return (
    <ul css={styles} onClick={() => setNavOpen(false)}>
      {links.map(link => {
        return (
          <li key={link.path}>
            <AnchorLink href={link.path}>
              <span className="grad-underline">{link.name}</span>
            </AnchorLink>
          </li>
        )
      })}
    </ul>
  )
}
