import { MaxWidth } from "@components/layout/max-width"
import css from "@emotion/css"
import Shortcodes from "@jetshop/flight-shortcodes"
import { colors } from "@theme/"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useInView } from "react-intersection-observer"
import { animated, useSpring } from "react-spring"

const styles = css`
  background: black;
  padding-bottom: 0;
  margin-bottom: 0;
  h2 {
    color: ${colors.blue};
    margin-bottom: 1em;
  }

  ul {
    display: flex;
    justify-content: space-between;
    color: white;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    @media screen and (max-width: 61em) and (min-width: 30em) {
      justify-content: center;
      li {
        flex-basis: 0;
      }
    }
  }

  li {
    margin: 2em 0;
    @media screen and (max-width: 55em) {
      margin: 2em;
    }
    @media screen and (max-width: 50em) {
      margin: 0.5em;
    }
    min-width: 120px;
    flex-basis: 0;
    padding: 0 0.25em;
    p {
      line-height: 1.5;
    }
  }

  .number {
    font-weight: 700;
    font-family: Montserrat;
    margin-bottom: 0;
    line-height: 1;
    > div {
      font-size: 5.7em;
      display: flex;
      align-items: center;
      @media screen and (max-width: 61em) and (min-width: 30em) {
        font-size: 5em;
      }
      > div {
        font-size: 0.2em;
        font-weight: normal;
        line-height: 0;
        margin-bottom: 1em;
      }
    }
  }
`
export function OurPeople() {
  const { data } = useStaticQuery(graphql`
    query People {
      data: datoCmsPeopleSection {
        title
        counter {
          percentage
          id
          color {
            hex
          }
          childDatoCmsTextNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)

  return (
    <section css={styles} id="our-people">
      <MaxWidth
        css={css`
          padding-bottom: 1em;
        `}
      >
        <h2>{data.title}</h2>

        <ul>
          {data.counter.map(counter => (
            <li key={counter.id}>
              <Counter percentage={counter.percentage} />
              <div style={{ color: counter.color.hex }} className="about">
                <Shortcodes
                  components={{}}
                  content={
                    counter.childDatoCmsTextNode.childMarkdownRemark.html
                  }
                />
              </div>
            </li>
          ))}
        </ul>
      </MaxWidth>
    </section>
  )
}

function Counter({ percentage }) {
  const [props, set] = useSpring(() => ({
    from: { number: 10 },
    config: { duration: 1500 },
    reset: true,
  }))
  const [ref, inView] = useInView({ rootMargin: "100px" })

  set({ number: inView ? +percentage : 10 })

  return (
    <div className="number" ref={ref}>
      <div>
        <animated.span>
          {props.number.interpolate(val => val.toFixed(0))}
        </animated.span>
        <div>%</div>
      </div>
    </div>
  )
}
