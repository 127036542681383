import css from "@emotion/css"
import React from "react"
import { useNav } from "./nav/container"

const navIcon = css`
  color: white;
  position: relative;
  height: 2em;
  width: 2em;
  cursor: pointer;
  transition: all 0.1s linear;
  background: black;
  mix-blend-mode: difference;
  z-index: 9;

  .icons {
    position: fixed;
    z-index: 10;
  }

  svg {
    stroke: currentColor;
    position: absolute;
    top: 0;
    left: 0;
    height: 2em;
    width: 2em;
    transition: all 0.2s linear;
  }
  .vertical {
  }
  path {
    stroke: currentColor;
    stroke-width: 8;
  }
`
export function NavIcon() {
  const { navOpen, setNavOpen } = useNav()

  return (
    <div
      css={[
        navIcon,
        navOpen &&
          css`
            transform: translateX(16px);
            position: fixed;
            top: 2.5em;
            right: 2em;
            z-index: 999;
            mix-blend-mode: unset;
            background: transparent;
          `,
      ]}
      onClick={() => setNavOpen(o => !o)}
    >
      <div className="icons">
        <div
          css={css`
            color: black;
            svg {
              height: 2.1em;
              width: 2.1em;
            }
          `}
        >
          <svg
            className="vertical"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 3 114"
            css={
              navOpen &&
              css`
                transform: rotate(-135deg);
              `
            }
          >
            <path fill="none" d="M1.5 1.5v110.2" strokeLinecap="round" />
          </svg>
          <svg
            className="horizontal"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 3 114"
            css={css`
              transform: ${navOpen ? "rotate(135deg)" : "rotate(90deg)"};
            `}
          >
            <path fill="none" d="M1.5 1.5v110.2" strokeLinecap="round" />
          </svg>
        </div>
        <div
          css={css`
            color: white;
          `}
        >
          <svg
            className="vertical"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 3 114"
            css={
              navOpen &&
              css`
                transform: rotate(-135deg);
              `
            }
          >
            <path fill="none" d="M1.5 1.5v110.2" strokeLinecap="round" />
          </svg>
          <svg
            className="horizontal"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 3 114"
            css={css`
              transform: ${navOpen ? "rotate(135deg)" : "rotate(90deg)"};
            `}
          >
            <path fill="none" d="M1.5 1.5v110.2" strokeLinecap="round" />
          </svg>
        </div>
      </div>
    </div>
  )
}
