import React from "react"
import css from "@emotion/css"
import { useStaticQuery, graphql } from "gatsby"
import { MaxWidth } from "@components/layout/max-width"
import { colors } from "@theme/"

const style = css`
  color: white;
  background: ${colors.navy};

  img {
    height: 4rem;
  }
  .feat-img {
    text-align: center;
    max-width: 5rem;
  }
  ul {
    display: flex;
    text-align: center;
    padding-top: 2em;
    display: block;
    p {
      margin-top: 1em;
      font-size: 1.2em;
      @media screen and (max-width: 60em) {
        font-size: 1em;
      }
    }
    li {
      margin: 0 auto;
      margin-top: 2em;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img {
        max-width: 4em;
        margin-right: 2em;
      }
      p {
        text-align: left;
        line-height: 1.75;
        margin-top: 0;
      }
    }
  }

  ul,
  li {
    margin: 0;
    list-style: none;
  }
  li {
    padding: 0 0.5em;
  }
  p {
    line-height: 1.5;
  }
`

export function DoYou() {
  const { data } = useStaticQuery(graphql`
    query DoYou {
      data: datoCmsDoYou {
        features {
          id
          text
          image {
            url
            alt
          }
        }
        title
      }
    }
  `)

  return (
    <section css={style}>
      <MaxWidth>
        <h2>{data.title}</h2>

        <ul>
          {data.features.map(feat => {
            return (
              <li key={feat.id}>
                {feat.image && (
                  <div className="feat-img">
                    <img src={feat.image.url} alt={feat.image.alt} />
                  </div>
                )}
                <p>{feat.text}</p>
              </li>
            )
          })}
        </ul>
      </MaxWidth>
    </section>
  )
}
