import { ArrowButton } from "@components/Arrow"
import { MaxWidth } from "@components/layout/max-width"
import css from "@emotion/css"
import { colors } from "@theme/"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React, { useEffect, useMemo, useState } from "react"
import { useInView } from "react-intersection-observer"
import { animated, useSpring } from "react-spring"

const style = css`
  color: black;
  overflow: hidden;
  background: black;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 3em;
  .fb-posts {
    display: flex;
    flex-wrap: nowrap;
    font-size: 12px;
    line-height: 1.5;
    align-items: flex-end;
    margin-top: 1.5em;
  }

  .fb-post {
    flex: 0 0 16rem;
    margin: 0 0.3em;
    transition: all 0.3s linear;
    &.is-active {
      transform: scale(1.08);
    }
    :first-of-type {
      margin-left: 1em;
    }
    header {
      max-height: 5.25em;
      overflow: hidden;
      position: relative;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
  img {
    width: 16rem;
    height: 16rem;
    object-fit: cover;
    margin: 0;
  }

  a {
    color: ${colors.blue};
    text-decoration: none;
  }

  main {
    line-height: 0;
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  header,
  footer {
    background: white;
    opacity: 0;
    transition: all 0.3s linear;
    padding: 0.5em;
  }
  .is-active header,
  .is-active footer {
    opacity: 1;
  }
`

const itemWidth = 295.2

export function FB() {
  const {
    data: { totalCount, nodes: posts },
  } = useStaticQuery(graphql`
    query FBQuery {
      data: allFacebookFeed {
        nodes {
          message
          attachments {
            data {
              description
              title
              url
              type
            }
          }
          fbImg {
            childImageSharp {
              fixed(width: 288, height: 288) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          likes {
            summary {
              total_count
            }
          }
        }
        totalCount
      }
    }
  `)

  const [ref, inView] = useInView()

  const initialConf = useMemo(
    () => ({
      from: { x: 0 },
      to: { x: -totalCount * itemWidth },
      config: {
        duration: 16 * (totalCount * itemWidth),
      },
    }),
    [totalCount]
  )

  const [finished, setFinished] = useState(false)
  const [conf, setConf] = useState({ x: 0 })

  const props = useSpring({
    ...conf,
    reset: finished,
    onRest: ({ x }) => {
      if (x === -totalCount * itemWidth) {
        setFinished(true)
        setConf(initialConf)
        setFinished(false)
        return
      }

      typeof conf.onRest === "function" && conf.onRest({ x })
    },
  })

  useEffect(() => {
    inView && setConf(initialConf)
  }, [inView, initialConf])

  return (
    <div ref={ref}>
      <Posts
        {...{
          initialConf,
          props,
          totalCount,
          conf,
          reset: finished,
          setReset: setFinished,
          setConf,
          posts,
        }}
      />
    </div>
  )
}

export const jumpSpring = {
  mass: 1,
  tension: 210,
  friction: 20,
  clamp: true,
}

const scrollToNext = ({ props, totalCount, setConf, initialConf }) =>
  setConf({
    to: { x: props.x.value - itemWidth },
    config: jumpSpring,
    onRest: ({ x }) => {
      const itemsPassed = -(x / itemWidth)

      itemsPassed > totalCount
        ? setConf(initialConf)
        : setConf({
            ...initialConf,
            config: {
              // Keep the speed the same
              duration: 16 * ((totalCount - itemsPassed) * itemWidth),
            },
          })
    },
  })

const scrollToPrev = ({ setConf, props, initialConf }) =>
  setConf({
    to: { x: props.x.value + itemWidth },
    config: jumpSpring,
    onRest: () => {
      setConf(initialConf)
    },
  })

const scrollToLast = ({ setConf, totalCount }) =>
  setConf({
    to: { x: -totalCount * itemWidth },
    config: jumpSpring,
  })

const scrollToFirst = ({ setConf, initialConf }) =>
  setConf({
    to: { x: 0 },
    config: jumpSpring,
    onRest: () => {
      setConf(initialConf)
    },
  })

const buttons = css`
  display: flex;
  justify-content: space-between;
  background: black;
  padding-bottom: 2em;
`

const Posts = function Posts({
  initialConf,
  props,
  totalCount,
  setConf,
  posts,
}) {
  const handlePrev = () => {
    if (props.x.value >= -itemWidth) {
      scrollToFirst({ setConf, initialConf })
      return
    }
    scrollToPrev({ initialConf, props, setConf })
  }

  const handleNext = () => {
    const itemsPassed = -(props.x.value / itemWidth)
    if (itemsPassed > totalCount - 1) {
      scrollToLast({ totalCount, setConf })
      return
    }

    scrollToNext({ initialConf, props, setConf, totalCount })
  }

  return (
    <>
      <section css={style}>
        <animated.section
          className="fb-posts"
          style={{
            transform: props.x.interpolate(x => `translateX(${x}px)`),
            flex: `0 0 ${totalCount * itemWidth}px`,
          }}
        >
          {[...posts, ...posts].map((post, index) => {
            return <Post post={post} key={post.message + index} />
          })}
        </animated.section>
      </section>
      <div
        css={css`
          background: black;
        `}
      >
        <MaxWidth
          css={css`
            padding-top: 0;
            padding-bottom: 0;
          `}
        >
          <div css={buttons}>
            <ArrowButton
              css={css`
                color: ${colors.green};
                transform: rotate(180deg);
                :hover {
                  color: white;
                }
              `}
              onClick={handlePrev}
            />
            <ArrowButton
              css={css`
                color: ${colors.lightBlue};
                :hover {
                  color: white;
                }
              `}
              onClick={handleNext}
            />
          </div>
        </MaxWidth>
      </div>
    </>
  )
}

const Post = React.memo(function Post({ post }) {
  const [active, setActive] = React.useState(false)
  if (!post.fbImg || !post.fbImg.childImageSharp) return null;
  return (
    <div
      className={`fb-post ${active ? "is-active" : ""}`}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onClick={() => setActive(a => !a)}
    >
      <header>{post.message}</header>
      <main>
        <Img fixed={post.fbImg.childImageSharp.fixed} className="client-img" />
      </main>
      <footer>
        <span className="likes">
          <span role="img" aria-label="thumbs up">
            👍
          </span>{" "}
          {post.likes.summary.total_count} likes
        </span>
        <a
          href={post.attachments.data[0].url}
          target="_blank"
          rel="noopener noreferrer"
        >
          View post on Facebook
        </a>
      </footer>
    </div>
  )
})
