import React from "react"
import css from "@emotion/css"

const arrowButton = css`
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  :focus {
    outline: none;
  }
  svg {
    height: 0.75em;
  }
`

export function Arrow(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 15" {...props}>
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M0 9h56c-2 2-3 3.3-3 4 0 1 1 2 2 2s7-6.6 7-7.5c0-1-6-7.5-7-7.5s-2 1-2 2c0 .7 1 2 3 4H0v3z"
      />
    </svg>
  )
}

export function ArrowButton(props) {
  return (
    <button css={arrowButton} {...props}>
      <Arrow />
    </button>
  )
}
