import React from "react"
import css from "@emotion/css"
import { useStaticQuery, graphql } from "gatsby"
import { MaxWidth } from "@components/layout/max-width"
import Shortcodes from "@jetshop/flight-shortcodes"
import BackgroundImage from "gatsby-background-image"
import { Vimeo } from "@components/Vimeo"

const style = css`
  color: white;
  background-color: black;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: relative;
  text-align: left;
  @media screen and (max-width: 830px) {
    > .gatsby-image-wrapper:before {
      background-size: 200%;
      background-position: top center;
    }
  }
  .apex-logo {
    display: inline-block;
    margin-right: auto;
    width: 9em;
    @media screen and (max-width: 830px) {
      margin-top: 10rem;
    }
  }
  && h2 {
    font-size: 1.2em;
    font-weight: 500;
    max-width: 40ch;
    line-height: 1.5;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul {
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  li {
    flex: 0 0 calc(50% - 0.5em);
    text-align: center;
    @media screen and (max-width: 60em) {
      flex: 0 0 calc(50% - 1em);
      margin-top: 1em;
    }
    /* background: rgba(0, 0, 0, 0.2); */
    @media screen and (max-width: 980px) {
      font-size: 80%;
    }
  }
  h3 {
    font-size: 1em;
    letter-spacing: 0.01em;
    font-weight: 600;
    padding: 0.5em;
    background: RGBA(37, 109, 218, 1);
    margin-bottom: 0em;
  }
  p {
    padding: 0.5em;
    margin-top: 0;
  }

  /* Vimeo / list items split */
  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 830px) {
      flex-wrap: wrap;
      > :first-child {
        order: 2;
        margin-top: 2em;
      }
    }
    > * {
      @media screen and (max-width: 830px) {
        flex: 0 0 100%;
      }
      flex: 0 0 calc(50% - 0.5em);
    }
  }

  .bg-gradient {
    background: radial-gradient(
      farthest-corner at 100% -570px,
      rgba(0, 0, 0, 0) 0%,
      rgb(0, 0, 1) 100%
    );

    @media screen and (max-width: 830px) {
      background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0, 0, 1) 25%);
    }
  }
`

export function Apex() {
  const { data } = useStaticQuery(graphql`
    query Apex {
      data: datoCmsApex {
        title
        apexBackground {
          fluid(maxWidth: 4160) {
            ...GatsbyDatoCmsFluid
          }
        }
        logo {
          url
        }
        content {
          id
          title
          childDatoCmsTextNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)

  return (
    <section css={style} id="apex">
      <BackgroundImage fluid={data.apexBackground.fluid}>
        <div className="bg-gradient">
          <MaxWidth>
            <img src={data.logo.url} className="apex-logo" alt="" />
            <h2>{data.title}</h2>

            <section>
              <div className="vimeo-wrapper">
                <Vimeo id="363443331" playsinline />
              </div>
              <ul>
                {data.content.map(item => {
                  return (
                    <li key={item.id}>
                      <h3>{item.title}</h3>
                      <Shortcodes
                        content={
                          item.childDatoCmsTextNode.childMarkdownRemark.html
                        }
                        components={{}}
                      />
                    </li>
                  )
                })}
              </ul>
            </section>
          </MaxWidth>
        </div>
      </BackgroundImage>
    </section>
  )
}
