import qs from "qs"
import React from "react"
import css from "@emotion/css"

const styles = css`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

// Props may be overridden by Vimeo embed settings
export function Vimeo({
  id,
  autoPlay,
  controls,
  muted,
  background,
  playsinline = 1,
  loop,
  cover,
  ...rest
}) {
  const params = qs.stringify(
    {
      muted: muted ? 1 : 0,
      playsinline: playsinline ? 1 : 0,
    },
    {
      addQueryPrefix: true,
    }
  )

  return (
    <div css={styles} {...rest}>
      <iframe
        title="Vimeo embed"
        src={`https://player.vimeo.com/video/${id}${params}`}
        allow={`${autoPlay ? "autoplay;" : ""} fullscreen`}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  )
}
