import { ArrowButton } from "@components/Arrow"
import { MaxWidth } from "@components/layout/max-width"
import css from "@emotion/css"
import { colors } from "@theme/"
import { AnimatePresence, motion } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React, { useCallback, useMemo, useState, useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { animated, useSprings } from "react-spring"
import { jumpSpring } from "./fb"

const style = css`
  @media screen and (max-width: 40rem) {
    display: none;
  }
  overflow: hidden;
  background: white;
  h2 {
    color: ${colors.blue};
  }

  .clients {
    display: flex;
    flex-wrap: nowrap;
  }
  .client {
    background: RGBA(37, 109, 218, 1);
    transition: all 0.2s linear;
    height: 200px;
    flex: 0 0 260px;
    overflow: hidden;
    position: relative;
    margin: 4px;
    transform: translateZ(0);
    &.is-active {
      transform: scale(1.1);
      z-index: 9999;
    }
    > * {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
  .client-logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .client-img {
    height: 100%;
    z-index: 999;
  }
`

const itemWidth = 268

export function Clients() {
  const { data } = useStaticQuery(graphql`
    query Clients {
      data: datoCmsClientSection {
        title
        clients {
          name
          id
          logo {
            fixed(width: 220) {
              ...GatsbyDatoCmsFixed
            }
          }
          project {
            fluid(maxWidth: 260) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  `)

  const splitClients = useMemo(
    () =>
      [...data.clients, ...data.clients].reduce(
        (prev, curr, index) => {
          const even = index % 2
          even ? prev[1].push(curr) : prev[0].push(curr)

          return prev
        },
        [[], []]
      ),
    [data.clients]
  )

  const itemsInRow = data.clients.length / 2

  const [finished, setFinished] = useState([false, false])

  // const controls = useAnimation()
  const [ref, inView] = useInView({ rootMargin: "-100px" })
  const initialConfs = useMemo(
    () =>
      splitClients.map((_, i) => ({
        from: { x: 0 },
        to: { x: -itemsInRow * itemWidth },
        config: {
          duration: (16 + i / 2) * (itemsInRow * itemWidth),
        },
      })),
    [itemsInRow, splitClients]
  )

  const [confs, setConfs] = useState(splitClients.map((_, index) => ({ x: 0 })))

  useEffect(() => {
    inView && setConfs(initialConfs)
  }, [inView, initialConfs])

  const springs = useSprings(
    2,
    confs.map((conf, i) => {
      return {
        ...conf,
        reset: finished[i],
        onRest: ({ x }) => {
          if (x === -itemsInRow * itemWidth) {
            setFinished(f => {
              const newArr = [...f]
              newArr[i] = true
              return newArr
            })
            setConfs(c => {
              const newArr = [...c]
              newArr[i] = initialConfs[i]
              return newArr
            })
            setFinished(f => {
              const newArr = [...f]
              newArr[i] = false
              return newArr
            })
          }

          typeof conf.onRest === "function" && conf.onRest({ x })
        },
      }
    })
  )

  const maintainSpeed = useCallback(
    (x, i) => {
      const itemsPassed = -(x / itemWidth)
      const confs = initialConfs.map(conf => {
        return {
          ...conf,
          config: {
            // Keep the speed the same
            duration: 16 * ((itemsInRow - itemsPassed) * itemWidth),
          },
        }
      })

      // Only reset confs once
      setConfs(confs)
    },
    [initialConfs, itemsInRow]
  )

  const scrollToNext = useCallback(() => {
    const newConfs = springs.map((spring, i) => ({
      to: { x: spring.x.value - itemWidth },
      config: jumpSpring,
      onRest: ({ x }) => {
        i === 0 && maintainSpeed(x)
      },
    }))
    setConfs(newConfs)
  }, [maintainSpeed, springs])

  const scrollToPrev = useCallback(() => {
    const newConfs = springs.map((spring, i) => ({
      to: { x: spring.x.value + itemWidth },
      config: jumpSpring,
      onRest: ({ x }) => {
        i === 0 && maintainSpeed(x)
      },
    }))
    setConfs(newConfs)
  }, [maintainSpeed, springs])

  const scrollToFirst = useCallback(() => {
    const newConfs = springs.map((_, i) => ({
      to: { x: 0 },
      config: jumpSpring,
      onRest: ({ x }) => {
        i === 0 && maintainSpeed(x)
      },
    }))
    setConfs(newConfs)
  }, [maintainSpeed, springs])

  const scrollToLast = useCallback(() => {
    const newConfs = springs.map((_, i) => ({
      to: { x: -itemsInRow * itemWidth },
      config: jumpSpring,
    }))
    setConfs(newConfs)
  }, [itemsInRow, springs])

  const handleNext = () => {
    const itemsPassed = -(springs[0].x.value / itemWidth)
    if (itemsPassed > itemsInRow - 1) {
      scrollToLast()
    } else {
      scrollToNext()
    }
  }
  const handlePrev = () => {
    if (springs[0].x.value >= -itemWidth) {
      scrollToFirst()
    } else {
      scrollToPrev()
    }
  }

  return (
    <section css={style}>
      <MaxWidth
        css={css`
          padding-bottom: 2em;
        `}
      >
        <h2>{data.title}</h2>

        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <ArrowButton
            css={css`
              color: ${colors.green};
              transform: rotate(180deg);
              :hover {
                color: black;
              }
            `}
            onClick={handlePrev}
          />
          <ArrowButton
            css={css`
              color: ${colors.blue};
              :hover {
                color: black;
              }
            `}
            onClick={handleNext}
          />
        </div>
      </MaxWidth>
      <div ref={ref}>
        {springs.map((props, index) => (
          <ClientsSlider
            key={index}
            {...{
              clientsList: splitClients[index],
              props,
            }}
          />
        ))}
      </div>
    </section>
  )
}

const ClientsSlider = React.memo(function ClientsSlider({
  clientsList,
  props,
}) {
  const [activeClient, setActiveClient] = useState(null)
  return (
    <>
      <animated.section
        className="clients"
        style={{
          transform: props.x.interpolate(x => `translateX(${x}px)`),
          flex: `0 0 ${clientsList.length * itemWidth}px`,
        }}
        onMouseLeave={() => setActiveClient(null)}
      >
        {clientsList.map((client, index) => (
          <Client
            key={client.id + index}
            client={client}
            setActiveClient={setActiveClient}
            active={client.id === activeClient}
            index={index}
          />
        ))}
      </animated.section>
    </>
  )
})

const Client = React.memo(function Client({
  client,
  setActiveClient,
  active,
  index,
}) {
  return (
    <div
      className={`client ${active ? "is-active" : ""}`}
      onMouseEnter={() => setActiveClient(client.id)}
    >
      <AnimatePresence>
        {!active && (
          <motion.div
            key={client.id + index}
            animate={{ y: 0 }}
            exit={{ y: -200 }}
            style={{ height: "100%", zIndex: 999, position: "relative" }}
            transition={{
              y: { type: "spring", stiffness: 900, damping: 200 },
            }}
          >
            <Img fluid={client.project.fluid} className="client-img" />
          </motion.div>
        )}
      </AnimatePresence>
      <div className="client-logo-wrapper">
        <Img fixed={client.logo.fixed} className="client-logo" />
      </div>
    </div>
  )
})
