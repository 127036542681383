import React, { useMemo, useState, useContext } from "react"

const NavContext = React.createContext(undefined)

export function useNav() {
  const context = useContext(NavContext)

  if (!context) throw new Error("Forgot to wrap with NavProvider")

  return { ...context }
}

export function NavProvider(props) {
  const [navOpen, setNavOpen] = useState(false)

  const value = useMemo(
    () => ({
      navOpen,
      setNavOpen,
    }),
    [navOpen]
  )

  return <NavContext.Provider value={value} {...props} />
}
