import React, { useRef } from "react"
import css from "@emotion/css"
import { Menu } from "./menu"
import { useNav } from "./container"
import { useOnClickOutside } from "@hooks/useOnClickOutside"
import { SocialLinks } from "@components/social-links"
import { colors } from "@theme/"

const styles = css`
  z-index: 999;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: auto;
  color: white;
  background: rgba(37, 109, 218, 0.95);
  font-family: Montserrat;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease;
`

const socialLinks = css`
  text-align: right;
  padding: 2em 0.5em;
  a {
    color: black;
    transition: all 0.2s linear;
    :hover {
      color: ${colors.lightBlue};
    }
  }
  svg {
    height: 1.5em;
    margin-right: 0.5em;
  }
`

export function Slideout() {
  const { navOpen, setNavOpen } = useNav()

  const ref = useRef()

  useOnClickOutside({
    action: () => setNavOpen(false),
    condition: navOpen,
    ref,
  })

  return (
    <nav
      css={styles}
      ref={ref}
      style={{
        "-ms-transform": navOpen ? "translateX(0)" : "translateX(100%)",
        transform: navOpen ? "translateX(0)" : "translateX(calc(100% + 1px))",
      }}
    >
      <Menu />
      {navOpen && (
        <div css={socialLinks}>
          <SocialLinks />
        </div>
      )}
    </nav>
  )
}
