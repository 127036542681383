import Shortcodes from "@jetshop/flight-shortcodes"
import { useStaticQuery, graphql } from "gatsby"
import React, { useRef, useEffect } from "react"
import { MaxWidth } from "@components/layout/max-width"
import css from "@emotion/css"
import { colors } from "@theme/"
import { useInView } from "react-intersection-observer"

const style = css`
  background: white;
  color: white;

  h2 {
    color: ${colors.blue};
  }

  .expertise-item {
    width: 33.333333333%;
    @media screen and (max-width: 60em) {
      width: 50%;
      font-size: 0.85em;
      height: 15em;
      font-weight: 400;
      line-height: 1.5;
    }
    height: 12em;
    background: rgba(0, 46, 107, 0.3);
    z-index: 5;
    border: 5px solid #fff;
    padding: 2em;
    color: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    font-weight: 800;
    transition: transform 0.2s ease-in;
    :last-of-type {
      @media screen and (min-width: 60em) {
        display: none;
      }
    }
    h3 {
      margin: 0;
      padding: 0;
      line-height: 1.5;
      font-size: 1.2em;
    }

    p {
      padding: 0.2em 0;
      margin: 0;
      font-size: 0.888888889em;
      opacity: 0;
    }

    &[data-active="true"] {
      background: rgba(37, 109, 218, 1);
      opacity: 1;
      z-index: 10;
      border-color: transparent;
      transform: scale(1.055);
      p {
        transition: all 1s linear;
        opacity: 1;
      }
    }
  }
`

export function Expertise() {
  const { data } = useStaticQuery(graphql`
    query Expertise {
      data: datoCmsExpertiseSection {
        title
        item {
          id
          title
          childDatoCmsTextNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)

  return (
    <section css={style} id="our-expertise">
      <MaxWidth>
        <h2>{data.title}</h2>
        <VideoBackground>
          {data.item.map(item => (
            <Item key={item.id} item={item} />
          ))}
        </VideoBackground>
      </MaxWidth>
    </section>
  )
}

function Item({ item }) {
  const [active, setActive] = React.useState(false)

  const html = item.childDatoCmsTextNode.childMarkdownRemark.html

  return (
    <div
      className="expertise-item"
      data-active={active}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      {!active ? (
        <h3>{item.title}</h3>
      ) : (
        <Shortcodes content={html} components={{}} />
      )}
    </div>
  )
}

const videoStyles = css`
  position: relative;
  overflow: hidden;
  padding: 1em;
  margin: -1em;
  background: black;
  .grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 5px solid #fff;
    border-width: 5px 10px;
    outline: 22px solid #fff;
    font-family: Nunito Sans, sans-serif;
    z-index: 2;
    position: relative;
    margin: 0 -10px;
  }
  video {
    min-width: 100%;
    min-height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 0;
    pointer-events: none;
    opacity: 0.6;
  }
`

function VideoBackground(props) {
  const videoRef = useRef()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (!videoRef.current) return

    inView ? videoRef.current.play() : videoRef.current.pause()
  }, [inView, ref])
  return (
    <div css={videoStyles} ref={ref}>
      <div className="grid">{props.children}</div>

      <video muted playsInline autoPlay loop ref={videoRef}>
        <source
          src="https://res.cloudinary.com/dxbygibug/video/upload/ac_none/q_50/v1511380114/Frontier_Party_video_02.webm"
          type="video/webm"
        />
        <source
          src="https://res.cloudinary.com/dxbygibug/video/upload/ac_none/q_50/v1511380114/Frontier_Party_video_02.mp4"
          type="video/mp4"
        />
        <source
          src="https://res.cloudinary.com/dxbygibug/video/upload/ac_none/q_50/v1511380114/Frontier_Party_video_02.ogv"
          type="video/ogg"
        />
      </video>
    </div>
  )
}
