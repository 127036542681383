import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import css from "@emotion/css"

const style = css`
  color: white;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;

  h1 {
    font-size: 2.75rem;
    line-height: 1.5;

    @media screen and (max-width: 70em) {
      font-size: 2.25em;
    }

    @media screen and (max-width: 60em) {
      br {
        display: none;
      }
      font-size: 1.8em;
      margin-top: -3em;
    }
  }
`

const extended = css`
  position: absolute;
  top: 70vh;
  left: 0;
  right: 0;
  text-align: center;
  p {
    max-width: 70ch;
    font-size: 1.3em;
    margin: 0 auto;
    font-weight: 600;
    padding: 1em;
    @media screen and (max-width: 60em) {
      font-size: 1.1em;
    }
    @media screen and (max-width: 43em) {
      font-size: 1em;
      font-weight: 600;
    }
  }
`

export function Intro() {
  const { hero } = useStaticQuery(graphql`
    query HeroText {
      hero: datoCmsIntroSection {
        extended
        introNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)
  return (
    <section css={style}>
      <h1
        dangerouslySetInnerHTML={{
          __html: hero.introNode.childMarkdownRemark.html,
        }}
      />
      <section css={extended}>
        <p>{hero.extended}</p>
      </section>
    </section>
  )
}
