import { ArrowButton } from "@components/Arrow"
import { MaxWidth } from "@components/layout/max-width"
import css from "@emotion/css"
import { colors } from "@theme/"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React, { useMemo, useRef } from "react"
import { useSpring } from "react-spring"
import { jumpSpring } from "./fb"

const style = css`
  @media screen and (max-width: 40rem) {
    display: block;
  }
  display: none;
  background: white;
  h2 {
    color: ${colors.blue};
  }

  .wrapper {
    overflow-y: scroll;
    white-space: nowrap;
    width: 100%;
  }

  .all-clients {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 7.6rem;
    grid-gap: 2px 4px;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    padding-left: 2px;
    padding-right: 2px;
  }
`

export function MobileClients() {
  const { data } = useStaticQuery(graphql`
    query MobileClients {
      data: datoCmsClientSection {
        title
        clients {
          name
          id
          project {
            fluid(maxWidth: 260) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  `)

  const splitClients = useMemo(
    () =>
      data.clients.reduce(
        (prev, curr, index) => {
          if (index < 12) prev[0].push(curr)
          if (index >= 12 && index < 24) prev[1].push(curr)
          if (index >= 24) prev[2].push(curr)

          return prev
        },
        [[], [], []]
      ),
    [data.clients]
  )

  const scrollTarget = useRef()

  const handleNext = () => {
    const width = scrollTarget.current.offsetWidth
    setX({ x: scrollTarget.current.scrollLeft + width })
  }
  const handlePrev = () => {
    const width = scrollTarget.current.offsetWidth
    setX({ x: scrollTarget.current.scrollLeft - width })
  }

  const [_, setX] = useSpring(() => ({
    from: { x: 0 },
    config: jumpSpring,
    onFrame: props => {
      scrollTarget.current.scroll(props.x, 0)
    },
    onStart: () => {
      if (!scrollTarget.current) return
      scrollTarget.current.style.overflow = "hidden"
      setTimeout(function() {
        scrollTarget.current.style.overflow = ""
      }, 10)
    },
  }))

  return (
    <section css={style}>
      <MaxWidth>
        <h2>{data.title}</h2>
        <div
          css={css`
            display: flex;
            margin-bottom: 2em;
            justify-content: space-between;
          `}
        >
          <ArrowButton
            css={css`
              color: ${colors.green};
              transform: rotate(180deg);
              :hover {
                color: black;
              }
            `}
            onClick={handlePrev}
          />
          <ArrowButton
            css={css`
              color: ${colors.blue};
              :hover {
                color: black;
              }
            `}
            onClick={handleNext}
          />
        </div>
        <div className="wrapper" ref={scrollTarget}>
          {splitClients.map(clientsList => (
            <div
              css={css`
                display: inline-block;
                width: 100%;
                white-space: nowrap;
              `}
            >
              <ul className="all-clients">
                {clientsList.map(client => (
                  <Client client={client} key={client.id} />
                ))}
              </ul>
            </div>
          ))}
        </div>
      </MaxWidth>
    </section>
  )
}

const clientStyle = css`
  margin: 0;
  padding: 0;
  overflow: hidden;
  h4 {
    font-size: 0.75em;
    font-weight: normal;
    color: ${colors.blue};
    margin-top: 0.5em;
  }
`

function Client({ client }) {
  return (
    <li className="client" css={clientStyle}>
      <Img
        fluid={client.project.fluid}
        className="client-img"
        css={css`
          height: 75%;
          width: 100%;
        `}
      />
      <h4>{client.name}</h4>
    </li>
  )
}
